import React, { useContext } from 'react';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';
import ModalContainer from 'components/molecules/ModalContainer';

import Button from 'components/atoms/Button';
import topNavData from 'nav.json';
import { AuthContext } from 'context/authContext';

import PermissionsForm from 'components/organisms/PermissionsForm';
import RolesForm from 'components/organisms/RolesForm';

import { useParams } from 'react-router-dom';

import Permissions from 'helpers/defaultPermission.json';
import defaultRoles from 'helpers/defaultRoles';
import Toast from 'components/molecules/Toast';
import userService from 'services/userService';
import UserForm from 'components/organisms/UserForm';
import EnquiryForm from 'components/organisms/EnquiryForm';
import BlogForm from 'components/organisms/BlogForm';
// import TagForm from "components/organisms/TagForm";
import CategoryForm from 'components/organisms/CategoryForm';
import ContactForm from 'components/organisms/ContactForm';
import AuthorForm from 'components/organisms/AuthorForm';
import CareerForm from 'components/organisms/CareerForm';

function TopBar() {
  const { isLoading } = useContext(LoadingContext);

  const { refetch, hasPermission } = useContext(AuthContext);

  // eslint-disable-next-line prefer-const
  let { view: title, child } = useParams();

  // eslint-disable-next-line prefer-const
  let { buttons = [], subNav = [] } = topNavData.find(({ file }) => file === title);

  if (subNav?.length) {
    const { buttons: subNavButtons } = subNav.find(({ file }) => file === child) ?? { buttons: [] };
    if (child) {
      buttons = [...subNavButtons];
    } else {
      buttons = [...buttons, ...subNavButtons];
    }
  }

  const restorePermissions = async () => {
    try {
      const res = await userService.restoreRoles({
        permissions: Permissions,
        roles: defaultRoles(),
      });
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
        refetch();
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };

  return (
    <>
      <Header
        title={
          child
            ? child.split('-').join(' ')
            : title
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        }></Header>
    </>
  );
}

export default TopBar;
