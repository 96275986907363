import styled from 'styled-components/macro';

export const Content = styled.div`
  position: relative;
  padding: 5.9375rem 1.25rem 1.25rem;
  .upper {
    width: ${({ isActive }) => (isActive ? 'calc(100% - 185px)' : '100%')};
    margin-left: ${({ isActive }) => (isActive ? '185px' : '0')};
    padding: 25px;
    display: flex;
    flex-direction: column;
  }
  .dashboard {
    gap: 50px;
    display: grid;
  }
  .upper-head {
    padding: 0px;
  }
  &::before {
    content: '';
    width: 100%;
    height: 67px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--white);
  }

  @media (min-width: 992px) {
    padding: 6.14rem 0 1.25rem 5.4rem;
  }
  @media (max-width: 991px) {
    .upper {
      width: 100%;
      margin-left: 0;
      padding: 15px;
      gap: 10px;
    }

    padding: 4rem 1rem 1rem;
  }
  .user-actions-container {
    position: absolute;
    right: 1.25rem;
    top: 0;
    z-index: 9999;
    @media (max-width: 520px) {
      position: fixed;
    }
  }
`;
