import React, { useContext, useEffect, useState, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import blogService from 'services/commonService';
import { Error } from '../../molecules/Field/Field.styles';
import ImgPreview from '../../molecules/ImgPreview';
import Flex from 'components/atoms/Flex/Index';
import { Editor } from '@tinymce/tinymce-react';

function BlogForm({ blogData, onClose = () => {} }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);
  const [bannerImg, setBannerImg] = useState(null);
  const [description, setDescription] = useState('');
  const [arabicDescription, setArabicDescription] = useState('');
  const [error, setError] = useState(null);
  const [imgError, setImgError] = useState(null);
  const [site, setSite] = useState('');
  const [blog, setBlog] = useState(null);
  const siteOption = [
    {
      label: 'webevis',
      value: '.com',
    },
    {
      label: 'webevis AE',
      value: '.ae',
    },
    {
      label: 'Foodie',
      value: 'foodie',
    },
  ];
  const { category_data, category_loading } = blogService.GetCategories({}, refetch);
  const { authors_data } = blogService.GetAuthors({}, refetch);
  const { blog_data, blog_loading } = blogService.GetBlogById(blogData?._id);

  const categoryOpts = category_data?.categories.map(category => ({
    label: category.categoryTitle,
    value: category._id,
  }));

  const authorsOpts = authors_data?.authors.map(author => ({
    label: author.name,
    value: author._id,
  }));

  useEffect(() => {
    setBlog(blog_data);
  }, [blog_data]);
  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef.current && description) {
      editorRef.current.setContent(description);
    }
  }, [description]);

  const arabicEditorRef = useRef(null);
  useEffect(() => {
    if (arabicEditorRef.current && arabicDescription) {
      arabicEditorRef.current.setContent(arabicDescription);
    }
  }, [arabicDescription]);

  useEffect(() => {
    if (blog && !category_loading) {
      const currentSite = siteOption.find(data => data.value === blog?.site);
      setSite(currentSite || siteOption[0]);
      form.setFieldsValue({
        title: blog?.eng?.title ? blog?.eng?.title : blog?.title,
        site: currentSite || siteOption[0],
        authorId: authorsOpts?.find(data => data.value === blog?.authorId?._id),
        category: categoryOpts?.find(data => data.value === blog?.category?._id),
        metaTitle: blog?.eng?.metaTitle ? blog?.eng?.metaTitle : blog.metaTitle,
        metaDescription: blog?.eng?.metaDescription ? blog?.eng?.metaDescription : blog.metaDescription,
        arabicTitle: blog?.ar?.title,
        arabicMetaTitle: blog?.ar?.metaTitle,
        arabicMetaDescription: blog?.ar?.metaDescription,
      });
      setDescription(blog?.eng?.description ? blog?.eng?.description : blog?.description);
      setArabicDescription(blog?.ar?.description);
      setBannerImg(blog?.bannerImg);
    } else {
      console.log('not working,,,,,,,,,', { blog });
    }
  }, [blog, category_loading]);

  // const handleEditorChange = content => {
  //   setDescription(content);
  // };
  const onSubmit = async data => {
    let desc = description;
    if (editorRef?.current) {
      desc = editorRef.current.getContent();
    }
    let arabicDesc = arabicDescription;
    if (arabicEditorRef?.current) {
      arabicDesc = arabicEditorRef.current.getContent();
    }

    if (!bannerImg) {
      setImgError('Banner image is required');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      let ar;
      if (site?.value === '.ae') {
        ar = {
          title: data.arabicTitle,
          description: arabicDesc,
          metaTitle: data.arabicMetaTitle,
          metaDescription: data.arabicMetaDescription,
        };
      }
      const postData = {
        site: data?.site?.value,
        authorId: data?.authorId?.value,
        category: data?.category?.value,
        bannerImg,
        eng: {
          title: data.title,
          description: desc,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
        },
        ar,
      };
      let res;
      if (blog) {
        await blogService.updateBlog(blog._id, postData);
      } else {
        res = await blogService.addBlog(postData);
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message || 'Post saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleImage = e => {
    setBannerImg(e.target.value);
    setImgError(null);
  };
  const handleImageSizeLimit = (e, items, editor) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        var imageSize = items[i].getAsFile().size;

        if (imageSize > 200 * 1024) {
          e.preventDefault();

          editor.notificationManager.open({
            text: 'Image size exceeds 200 KB limit',
            type: 'error',
          });

          return false;
        }
      }
    }

    return true;
  };
  return (
    <>
      <Form form={form} onSubmit={onSubmit}>
        <Grid
          xs={1}
          lg={2}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            options={siteOption}
            isSearchable
            name="site"
            label="Choose Site"
            placeholder="Select site"
            hideSelectedOptions={false}
            value={site}
            onChange={({ target: { value } }) => {
              setSite(value);
              form.setFieldsValue({ site: value });
            }}
            closeMenuOnSelect={true}
            rules={[
              { required: true, message: 'Select atleast one site' },
              {
                transform: value => !value?.length,
                message: 'Select at least one site',
              },
            ]}>
            <Select />
          </Form.Item>

          <Form.Item
            sm
            options={categoryOpts}
            isSearchable
            name="category"
            label="Category"
            placeholder="Select Category"
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            rules={[
              { required: true, message: 'Select atleast one cateogry' },
              {
                transform: value => !value?.length,
                message: 'Select at least one cateogry',
              },
            ]}>
            <Select />
          </Form.Item>
        </Grid>
        {site?.value === '.ae' && <h3>English</h3>}
        <Grid
          xs={1}
          lg={2}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            type="text"
            label="Title"
            name="title"
            placeholder="Post Title"
            rules={[
              { required: true, message: 'Please enter post title' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,200}$/,
                message: 'Character Length should be between 5 and 200',
              },
            ]}>
            <Field />
          </Form.Item>

          <Form.Item
            sm
            type="text"
            label="Metatitle"
            name="metaTitle"
            placeholder="Metatitle"
            rules={[
              { required: true, message: 'Please enter meta title' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,}$/,
                message: 'Length should be atleast 5',
              },
            ]}>
            <Field />
          </Form.Item>

          <Form.Item
            sm
            type="text"
            label="Metadescription"
            name="metaDescription"
            placeholder="Metadescription"
            rules={[
              { required: true, message: 'Please enter post meta description' },
              {
                pattern: /^[A-Z]/,
                message: 'First character should be capital',
              },
              {
                pattern: /^.{5,}$/,
                message: 'Length should be atleast 5',
              },
            ]}>
            <Field />
          </Form.Item>

          <Form.Item
            sm
            options={authorsOpts}
            isSearchable
            name="authorId"
            label="Author"
            placeholder="Select Author"
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            rules={[
              { required: true, message: 'Select atleast one author' },
              {
                transform: value => !value?.length,
                message: 'Select at least one author',
              },
            ]}>
            <Select />
          </Form.Item>
        </Grid>

        <Grid
          xs={1}
          lg={1}
          css={`
            margin-top: 20px;
            align-items: center;
          `}>
          Editor
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
              // console.log({ editor: editor.initialized });
            }}
            initialValue={description}
            init={{
              image_resizing: true,
              height: 500,
              plugins: [
                'advlist',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'help',
                'wordcount',
                'directionality',
              ],
              toolbar:
                'undo redo | casechange blocks | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | link' +
                'ltr rtl | a11ycheck code table help | link',

              link_rel_list: [
                // { title: 'No Referrer', value: 'noreferrer' },
                // { title: 'External Link', value: 'external' },
                {
                  title: 'Do Follow',
                  value: 'dofollow',
                },
                {
                  title: 'No Follow',
                  value: 'nofollow',
                },
              ],
              content_style: 'img { width:100%; height:auto; }',
              menubar: 'insert',
              a11y_advanced_options: true,
              // directionality: lang?.value === 'ar' ? 'rtl' : 'ltr',
              setup: function (editor) {
                let savedSelection;
                editor.on('keyup', function (e) {
                  if (e.keyCode === 32 || e.keyCode === 13) {
                    editor.undoManager.add();
                    savedSelection = editor.selection.getBookmark(2, true);
                  }
                });
                editor.on('undo', function (e) {
                  if (savedSelection) {
                    editor.selection.moveToBookmark(savedSelection);
                  }
                });
                // // Handle the paste event to check image sizes
                // editor.on('paste drop', async function (e) {
                //   var items;
                //   if (e.type === 'paste') {
                //     var clipboardData = e.clipboardData || window.clipboardData;
                //     items = clipboardData.items;
                //   } else if (e.type === 'drop') {
                //     e.preventDefault();
                //     items = e.dataTransfer.items;
                //   }

                //   // Iterate through items and check if they are images
                //   for (var i = 0; i < items.length; i++) {
                //     if (items[i].type.indexOf('image') !== -1) {
                //       try {
                //         var compressedImage = await compressImage(items[i].getAsFile());
                //         const compressedSizeInBytes = Math.round((compressedImage.length * 3) / 4);
                //         if (compressedSizeInBytes > 200 * 1024) {
                //           e.preventDefault();

                //           editor.notificationManager.open({
                //             text: 'Image size exceeds 200 KB limit',
                //             type: 'error',
                //           });
                //           return false;
                //         } else {
                //           console.log(compressedImage);
                //           editor.insertContent('<img src="' + compressedImage + '" alt=" " />');
                //         }
                //       } catch (error) {
                //         console.error('Error compressing image:', error);
                //       }
                //     }
                //   }
                // });
                editor.on('paste', function (e) {
                  var clipboardData = e.clipboardData || window.clipboardData;
                  var items = clipboardData.items;
                  handleImageSizeLimit(e, items, editor);
                });

                editor.on('drop', function (e) {
                  var items = e.dataTransfer.items;

                  handleImageSizeLimit(e, items, editor);
                });
              },
            }}
          />
          <Error>{error}</Error>
        </Grid>

        {(blog_data?.site === '.ae' || site?.value === '.ae') && (
          <>
            <Grid
              xs={1}
              lg={2}
              direction={true}
              colGap={20}
              css={`
                align-items: center;
              `}>
              <h3>عربي</h3>
            </Grid>

            <Grid
              xs={1}
              lg={2}
              colGap={20}
              direction={true}
              css={`
                align-items: center;
              `}>
              <Form.Item
                sm
                type="text"
                label="عنوان (Title)"
                name="arabicTitle"
                placeholder="عنوان المشاركة"
                rules={[
                  { required: true, message: 'Please enter post title' },
                  {
                    pattern: /^.{5,200}$/,
                    message: 'Character Length should be between 5 and 200',
                  },
                ]}>
                <Field />
              </Form.Item>

              <Form.Item
                sm
                type="text"
                label="عنوان ميتا (Metatitle)"
                name="arabicMetaTitle"
                placeholder="عنوان ميتا"
                rules={[
                  { required: true, message: 'Please enter meta title' },
                  {
                    pattern: /^.{5,}$/,
                    message: 'Length should be atleast 5',
                  },
                ]}>
                <Field />
              </Form.Item>

              <Form.Item
                sm
                type="text"
                label="وصف ميتا (Metadescription)"
                name="arabicMetaDescription"
                placeholder="وصف ميتا"
                rules={[
                  { required: true, message: 'Please enter post meta description' },
                  {
                    pattern: /^.{5,}$/,
                    message: 'Length should be atleast 5',
                  },
                ]}>
                <Field />
              </Form.Item>
            </Grid>

            <Grid
              xs={1}
              lg={1}
              direction={true}
              css={`
                margin-top: 20px;
                align-items: center;
              `}>
              محرر
              <Editor
                onInit={(evt, editor) => {
                  arabicEditorRef.current = editor;
                }}
                initialValue={arabicDescription}
                init={{
                  image_resizing: true,
                  height: 500,
                  plugins: [
                    'advlist',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'help',
                    'wordcount',
                    'directionality',
                  ],
                  toolbar:
                    'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | link' +
                    'ltr rtl | a11ycheck code table help | link',

                  link_rel_list: [
                    // { title: 'No Referrer', value: 'noreferrer' },
                    // { title: 'External Link', value: 'external' },
                    {
                      title: 'Do Follow',
                      value: 'dofollow',
                    },
                    {
                      title: 'No Follow',
                      value: 'nofollow',
                    },
                  ],
                  content_style: 'img { width:100%; height:auto; }',
                  menubar: 'insert',
                  a11y_advanced_options: true,
                  directionality: 'rtl',
                  setup: function (editor) {
                    let savedSelection;
                    editor.on('keyup', function (e) {
                      if (e.keyCode === 32 || e.keyCode === 13) {
                        editor.undoManager.add();
                        savedSelection = editor.selection.getBookmark(2, true);
                      }
                    });
                    editor.on('undo', function (e) {
                      if (savedSelection) {
                        editor.selection.moveToBookmark(savedSelection);
                      }
                    });
                    // // Handle the paste event to check image sizes
                    // editor.on('paste drop', async function (e) {
                    //   var items;
                    //   if (e.type === 'paste') {
                    //     var clipboardData = e.clipboardData || window.clipboardData;
                    //     items = clipboardData.items;
                    //   } else if (e.type === 'drop') {
                    //     e.preventDefault();
                    //     items = e.dataTransfer.items;
                    //   }

                    //   // Iterate through items and check if they are images
                    //   for (var i = 0; i < items.length; i++) {
                    //     if (items[i].type.indexOf('image') !== -1) {
                    //       try {
                    //         var compressedImage = await compressImage(items[i].getAsFile());
                    //         const compressedSizeInBytes = Math.round((compressedImage.length * 3) / 4);
                    //         if (compressedSizeInBytes > 200 * 1024) {
                    //           e.preventDefault();

                    //           editor.notificationManager.open({
                    //             text: 'Image size exceeds 200 KB limit',
                    //             type: 'error',
                    //           });
                    //           return false;
                    //         } else {
                    //           console.log(compressedImage);
                    //           editor.insertContent('<img src="' + compressedImage + '" alt=" " />');
                    //         }
                    //       } catch (error) {
                    //         console.error('Error compressing image:', error);
                    //       }
                    //     }
                    //   }
                    // });
                    editor.on('paste', function (e) {
                      var clipboardData = e.clipboardData || window.clipboardData;
                      var items = clipboardData.items;
                      handleImageSizeLimit(e, items, editor);
                    });

                    editor.on('drop', function (e) {
                      var items = e.dataTransfer.items;

                      handleImageSizeLimit(e, items, editor);
                    });
                  },
                }}
              />
              <Error>{error}</Error>
            </Grid>
          </>
        )}

        <Flex
          direction="column"
          justify="flex-start"
          align="flex-start"
          wrap="no-wrap"
          gap={bannerImg ? '10px' : '0px'}>
          {bannerImg && (
            <Grid style={{ width: '100%', marginBottom: '20px' }} xs={1} lg={1}>
              <ImgPreview src={bannerImg} alt="bannerImage" />
            </Grid>
          )}
          <Field
            noMargin={true}
            label="Upload File"
            name="fileInput"
            type="chooseFile"
            onChange={handleImage}
            accept="image/gif, image/jpeg, image/png,image/webp"
          />
          <Error>{imgError}</Error>
        </Flex>
        <Button loading={loading} type="primary" htmlType="submit" style={{ margin: '0 auto' }}>
          Save
        </Button>
      </Form>
    </>
  );
}

export default BlogForm;
