import { Fetch } from '../helpers/fetchWrapper';
import { useEffect, useState } from 'react';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const queryService = {
  _url: `${process.env.REACT_APP_QUERY_URL}/query`,

  GetAllEnquiries(searchQuery, refetch) {
    const [enquiries, setEnquiries] = useState({
      totalItems: 0,
      queries: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllEnquiries(searchQuery))
        .then(res => {
          setEnquiries(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.searchText,
      searchQuery.filterText,
      searchQuery.filterAssignee,
      searchQuery.startDate,
      searchQuery.endDate,
      searchQuery.getAll,
      searchQuery.refetch,
      searchQuery.status,
    ]);
    return {
      enquiries_loading: status === STATUS.LOADING,
      enquiries_error: status === STATUS.ERROR ? status : '',
      enquiries_data: enquiries,
    };
  },
  GetAllContacts(searchQuery, refetch) {
    const [contacts, setContacts] = useState({
      contacts: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllContacts(searchQuery))
        .then(res => {
          setContacts(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      contacts_loading: status === STATUS.LOADING,
      contacts_error: status === STATUS.ERROR ? status : '',
      contacts_data: contacts,
    };
  },
  GetAllSubscriber(searchQuery, refetch) {
    const [subscriber, setSubscriber] = useState({
      totalItems: 0,
      subscriber: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllSubscribers(searchQuery))
        .then(res => {
          setSubscriber(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      subscribers_loading: status === STATUS.LOADING,
      subscribers_error: status === STATUS.ERROR ? status : '',
      subscribers_data: subscriber,
    };
  },
  GetAdminTimeSlots(refetch) {
    const [timeSlots, setTimeSlots] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdminTimeSlots())
        .then(res => {
          setTimeSlots(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch]);
    return {
      timeSlots_loading: status === STATUS.LOADING,
      timeSlots_error: status === STATUS.ERROR ? status : '',
      timeSlots_data: timeSlots,
    };
  },
  GetMeetings(searchQuery, refetch) {
    const [meetings, setMeetings] = useState({
      totalItems: 0,
      meetings: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllMeetings(searchQuery))
        .then(res => {
          setMeetings(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      meetings_loading: status === STATUS.LOADING,
      meetings_error: status === STATUS.ERROR ? status : '',
      meetings_data: meetings,
    };
  },
  GetPositions(searchQuery, refetch) {
    const [positions, setPositions] = useState({
      totalItems: 0,
      positions: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllPositions(searchQuery))
        .then(res => {
          setPositions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      positions_loading: status === STATUS.LOADING,
      positions_error: status === STATUS.ERROR ? status : '',
      positions_data: positions,
    };
  },
  GetBookedTimeSlots(searchQuery, refetch) {
    const [bookedTimeSlots, setBookedTimeSlots] = useState({
      availableSlots: [],
      bookedSlots: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllBookedTimeSlot())
        .then(res => {
          setBookedTimeSlots({
            availableSlots: res.defaultAvailableSlots,
            bookedSlots: res.slotsTaken,
          });
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, []);
    return {
      bookedTimeSlots_loading: status === STATUS.LOADING,
      bookedTimeSlots_error: status === STATUS.ERROR ? status : '',
      bookedTimeSlots_data: bookedTimeSlots,
    };
  },
  GetUpcomingMeet(searchQuery, refetch) {
    const [enquiries, setEnquiries] = useState({
      totalItems: 0,
      queries: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getUpcomingMeet(searchQuery))
        .then(res => {
          setEnquiries(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery.startDate, searchQuery.endDate, searchQuery.time]);
    return {
      enquiries_loading: status === STATUS.LOADING,
      enquiries_error: status === STATUS.ERROR ? status : '',
      enquiries_data: enquiries,
    };
  },
  GetTotalMeetings(searchQuery) {
    const [enquiries, setEnquiries] = useState();
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getTotalMeetings(searchQuery))
        .then(res => {
          setEnquiries(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery.startDate, searchQuery.time]);
    return {
      enquiries_loading: status === STATUS.LOADING,
      enquiries_error: status === STATUS.ERROR ? status : '',
      total_enquiries: enquiries,
    };
  },
  async createEnquiry(payload) {
    let res = await Fetch.post(`${this._url}/enquiry?fromAdmin=true`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateEnquiry(id, payload) {
    let res = await Fetch.put(`${this._url}/enquiry/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createMeeting(payload) {
    let res = await Fetch.post(`${this._url}/bookedtimeslots`, payload);
    if (res.status >= 200 && res.status <= 400) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllBookedTimeSlot() {
    let res = await Fetch.get(`${this._url}/bookedtimeslots`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
  },
  async deleteEnquiry(id) {
    let res = await Fetch.delete(`${this._url}/enquiry/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllEnquiries({
    page = 1,
    pageSize = 10,
    searchText,
    filterText,
    filterAssignee,
    startDate,
    endDate,
    getAll = false,
    sort = 'updated_at',
    status,
  }) {
    let res = await Fetch.get(
      `${this._url}/enquiry?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&filterText=${filterText}&filterAssignee=${filterAssignee}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&sort=${sort}&status=${status}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        enquiries: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createContact(payload) {
    let res = await Fetch.post(`${this._url}/contact`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateContact(id, payload) {
    let res = await Fetch.put(`${this._url}/contact/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteContact(id) {
    let res = await Fetch.delete(`${this._url}/contact/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllContacts({ page = 1, pageSize = 10, searchText, startDate, endDate, getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/contact?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        contacts: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllPositions({ page = 1, pageSize = 10, searchText, startDate, endDate, getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/positions?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        positions: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllEnquiryUsers() {
    let res = await Fetch.get(`${this._url}/getEnquiryUser`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        allEnquiryUsers: res.data,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async unSubUser(id) {
    let res = await Fetch.put(`${this._url}/subscriber/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteSub(id) {
    let res = await Fetch.delete(`${this._url}/subscriber/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deletePosition(id) {
    let res = await Fetch.delete(`${this._url}/position/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllSubscribers({ page, pageSize, searchText, startDate, endDate, getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/subscriber?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        subscribers: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async addNote(id, data) {
    let res = await Fetch.post(`${this._url}/enquiry/add-note/${id}`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async changeStatus(id, data) {
    let res = await Fetch.put(`${this._url}/enquiry/changeStatus/${id}`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  // async importCsv(formData, view) {
  //   const res = await fetch(`${this._url}/${view === 'subscriber' ? 'importCsv' : 'contactsImportCsv'}`, {
  //     method: 'POST',
  //     body: formData,
  //   });
  //   if ((res?.status >= 200 && res?.status < 300) || res.status === 404) {
  //     return res;
  //   }
  // },
  async importCsv(formData, view) {
    const res = await Fetch.upload(
      `${this._url}/${view === 'subscriber' ? 'importCsv' : 'contactsImportCsv'}`,
      formData,
    );
    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async csvExport(view) {
    const backendURL = `${process.env.REACT_APP_QUERY_URL}/query/${
      view === 'subscriber' ? 'exportCsv' : 'contactsExportCsv'
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = backendURL;
    downloadLink.target = '_blank';
    downloadLink.download = 'subscribers.csv';
    downloadLink.click();
  },
  async downloadResume(data) {
    let res = await Fetch.post(`${this._url}/downloadResume`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res?.data;
    }
  },
  async addPosition(data) {
    let res = await Fetch.post(`${this._url}/position`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updatePosition(id, data) {
    let res = await Fetch.put(`${this._url}/position/${id}`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getApplicantInfo(id) {
    let res = await Fetch.get(`${this._url}/applicant/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res?.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAdminTimeSlots() {
    let res = await Fetch.get(`${this._url}/admintimeslots`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data.defaultSlots;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateAdminTimeSlots(data) {
    let res = await Fetch.put(`${this._url}/admintimeslots`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data.defaultSlots;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllMeetings({ page, pageSize, searchText, startDate, endDate, getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/bookedtimeslots?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        meetings: res.slotsTaken,
        totalItems: res.slotsTaken.length,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteMeeting({ id = '', eventId = '' }) {
    let res = await Fetch.delete(`${this._url}/bookedtimeslots/${id}/${eventId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async addAssignee(id, data, email) {
    let res = await Fetch.post(`${this._url}/addAssignee/${id}`, { enquiryId: data, email });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllAssignees() {
    let res = await Fetch.get(`${this._url}/allAssignee`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res.data.map(val => ({
          label: val?.username.charAt(0).toUpperCase() + val?.username.slice(1).toLowerCase(),
          value: val?._id,
        })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getUpcomingMeet({ startDate, endDate, time }) {
    let res = await Fetch.get(`${this._url}/upcomingMeet?startDate=${startDate}&endDate=${endDate}&time=${time}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        enquiries: res.items,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getTotalMeetings({ startDate, time }) {
    let res = await Fetch.get(`${this._url}/totalMeetings?startDate=${startDate}&time=${time}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        enquiries: res.items,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async meetingResult(id, payload) {
    let res = await Fetch.put(`${this._url}/bookedtimeslots/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default queryService;
