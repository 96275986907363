import { useState, useEffect } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from 'helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const blogService = {
  _url: `${process.env.REACT_APP_BLOG_URL}/common`,

  GetBlogs(searchQuery, refetch) {
    const [blogs, setBlogs] = useState({
      totalItems: 0,
      blogs: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getBlogs(searchQuery))
        .then(res => {
          setBlogs(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      blogs_loading: status === STATUS.LOADING,
      blogs_error: status === STATUS.ERROR ? status : '',
      blogs_data: blogs,
    };
  },

  GetCategories(searchQuery, refetch) {
    const [categories, setCategories] = useState({
      totalItems: 0,
      categories: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCategories(searchQuery))
        .then(res => {
          setCategories(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      category_loading: status === STATUS.LOADING,
      category_error: status === STATUS.ERROR ? status : '',
      category_data: categories,
    };
  },

  GetAuthors(searchQuery, refetch) {
    const [authors, setAuthors] = useState({
      totalItems: 0,
      authors: [],
    });

    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAuthors(searchQuery))
        .then(res => {
          setAuthors(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [JSON.stringify(searchQuery), refetch]);
    return {
      authors_loading: status === STATUS.LOADING,
      authors_error: status === STATUS.ERROR ? status : '',
      authors_data: authors,
    };
  },
  GetBlogById(blog_id) {
    const [blog, setBlog] = useState({});
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      if (!blog_id) {
        setStatus(STATUS.SUCCESS);
        setBlog(null);
      } else {
        setStatus(STATUS.LOADING);
        cancellablePromise(this.getBlogDetail(blog_id))
          .then(res => {
            setBlog(() => res);
            setStatus(STATUS.SUCCESS);
          })
          .catch(() => setStatus(STATUS.ERROR));
      }
    }, [blog_id]);
    return {
      blog_loading: status === STATUS.LOADING,
      blog_error: status === STATUS.ERROR ? status : '',
      blog_data: blog,
    };
  },
  async getBlogs({ page, pageSize, searchText, filterPlatform, filterCategory, startDate, endDate, getAll = false }) {
    let res =
      await Fetch.get(`${this._url}/all?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterCategory=${filterCategory}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&domain=${filterPlatform}
    `);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        blogs: res?.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getCategories({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = true }) {
    let res =
      await Fetch.get(`${this._url}/allCategories?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}
    `);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        categories: res?.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getAuthors({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = false }) {
    let res =
      await Fetch.get(`${this._url}/authors?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}
`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        authors: res?.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async categoryFiltersOptions({ getAll = true }) {
    let res = await Fetch.get(`${this._url}/allCategories?getAll=${getAll}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res.items.map(({ categoryTitle, _id }) => ({
          label: categoryTitle,
          value: _id,
        })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async addBlog(values) {
    let res = await Fetch.post(`${this._url}/create`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async addAuthor(values) {
    let res = await Fetch.post(`${this._url}/author`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async addCategory(values) {
    let res = await Fetch.post(`${this._url}/createCategory`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateBlog(id, payload) {
    let res = await Fetch.put(`${this._url}/single/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateAuthor(id, payload) {
    let res = await Fetch.put(`${this._url}/author/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateCategory(id, payload) {
    let res = await Fetch.put(`${this._url}/singleCategory/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteBlog(id) {
    let res = await Fetch.delete(`${this._url}/single/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteAuthor(id) {
    let res = await Fetch.delete(`${this._url}/author/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteCategory(id) {
    let res = await Fetch.delete(`${this._url}/singleCategory/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getBlogDetail(blog_id) {
    let res = await Fetch.get(`${this._url}/single/${blog_id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getCommentsByBlog(blog_id) {
    let res = await Fetch.get(`${this._url}/comment/${blog_id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async approveComment(commentId) {
    let res = await Fetch.patch(`${this._url}/approve/${commentId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteComment(commentId) {
    let res = await Fetch.delete(`${this._url}/comment/${commentId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default blogService;
