import React, { useContext, useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import blogService from 'services/commonService';
import ImgPreview from 'components/molecules/ImgPreview/index';
import Flex from '../../atoms/Flex/Index';
import { Error } from '../../molecules/Field/Field.styles';

function AuthorForm({ author, onClose = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({ valid: false });
  const [authorImg, setAuthorImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);
  const [imgError, setImgError] = useState(null);

  useEffect(() => {
    if (author) {
      form.setFieldsValue({
        name: author?.name,
        email: author?.email,
        details: author?.details,
        linkedin: author?.linkedin,
        instagram: author?.instagram,
      });
      setAuthorImg(author?.authorImg);
    }
  }, [author]);

  const onSubmit = async data => {
    if (!authorImg) {
      setImgError('Author image is required');
      return;
    }

    try {
      const postData = {
        name: data.name,
        email: data?.email,
        details: data?.details,
        linkedin: data.linkedin,
        instagram: data.instagram,
        authorImg,
      };
      setLoading(true);
      let response;
      if (author) {
        response = await blogService.updateAuthor(author?._id, postData);
      } else {
        response = await blogService.addAuthor(postData);
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: response.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleImage = e => {
    setAuthorImg(e.target.value);
    setImgError(null);
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Name"
          name="name"
          placeholder="Author's Name"
          rules={[
            { required: true, message: 'Please enter name' },
            { max: 40, message: 'Name should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          rules={[
            { required: true, message: 'Please enter email address' },
            { email: true, message: 'Please enter a valid email' },
            { max: 40, message: 'Email should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>
      </Grid>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item sm type="text" label="Linkedin Address" name="linkedin" placeholder="Linkedin Address">
          <Field />
        </Form.Item>
        <Form.Item sm type="text" placeholder="Instagram Address" name="instagram" label="Instagram Address">
          <Field />
        </Form.Item>
        <div />
      </Grid>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}></Grid>
      <Form.Item
        type="textarea"
        label="Author Details"
        name="details"
        placeholder="Share Important Details About Author"
        rules={[{ required: true, message: 'Please enter author details' }]}>
        <Field />
      </Form.Item>
      <Flex direction="column" justify="flex-start" align="flex-start" wrap="no-wrap" gap={authorImg ? '10px' : '0px'}>
        {authorImg && (
          <Grid style={{ width: '100%', marginBottom: '20px' }} xs={1} lg={1}>
            <ImgPreview src={authorImg} alt="authorImg" />
          </Grid>
        )}
        <Field
          noMargin={true}
          label="Upload File"
          name="fileInput"
          type="chooseFile"
          onChange={handleImage}
          accept="image/gif, image/jpeg, image/png,image/webp"
        />
        <Error>{imgError}</Error>
      </Flex>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        style={{ marginTop: '20px' }}
        style={{ margin: '0 auto' }}>
        Save
      </Button>
    </Form>
  );
}

export default AuthorForm;
