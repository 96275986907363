import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import queryService from 'services/queryService';

function CareerForm({ position, onClose = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({ valid: false });
  const [loading, setLoading] = useState(false);
  const { refetch } = useContext(AuthContext);

  useEffect(() => {
    if (position) {
      form.setFieldsValue({
        ...position,
      });
      setState({
        ...position,
      });
    }
  }, [position]);
  const onSubmit = async data => {
    const updatedPayload = { ...data, ...state };
    const { valid, ...rest } = updatedPayload;
    try {
      setLoading(true);
      let response;
      if (position) {
        response = await queryService.updatePosition(position._id, rest);
      } else {
        response = await queryService.addPosition(rest);
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: response.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={1}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Position Title"
          name="title"
          placeholder="Title"
          rules={[
            { required: true, message: 'Please enter title' },
            {
              pattern: /^[A-Z]/,
              message: 'First character should be capital',
            },
            { max: 40, message: 'Title should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>
      </Grid>
      <Button loading={loading} type="primary" htmlType="submit" style={{ margin: '0 auto' }}>
        Save
      </Button>
    </Form>
  );
}

export default CareerForm;
